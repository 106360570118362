import { Head } from '@inertiajs/react';
import { useSoftUIController } from "@/context";

function MetaHead(seo) {

    //console.log('sep => ', seo)

  //  const { favicon } = useAppContext();
  const [controller, dispatch] = useSoftUIController();
  
    return (
        <>
            {seo.seo ?
                <Head>
                    <title>{seo.seo['title']}</title>
                    <meta head-key="description" name="description" content={seo.seo['description']} />
                    { seo['metaKeywords'] != '' ? <meta head-key="keywords" name="keywords" content={seo.seo['metaKeywords']} /> : null } 
                    <link rel="icon" type="image/svg+xml" href={controller.favicon} />
                    
                </Head>
            : 
                <Head>
                    <link rel="icon" type="image/svg+xml" href={controller.favicon} />
                </Head>
       
            }
        </>
    )

}

export default MetaHead;